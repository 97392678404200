import React, { useState } from "react"
import ReactAudioPlayer from 'react-audio-player';
import { useParams } from "react-router-dom";


const styles = {
    display: "flex",
    width: "100vw",
    height: "100vh",
    backgroundImage: "url('./Ewness.png'),linear-gradient(rgba(0, 0, 0, 0.5),35%, rgba(255, 255, 255, 0))",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"

}

const audioStyle = {
    display: "flex",
    margin: '30vh auto auto auto'
}

const baseUrl = process.env.NODE_ENV === 'production' ?
    'http://ewness.com/sound/qrcode/'
    : 'http://localhost:8000/sound/qrcode/'

function AudioPlayer() {
    const { code } = useParams();
    const audioSrc = baseUrl + code;

    return <div style={styles}>
        <div style={audioStyle}>
            <ReactAudioPlayer
                src={audioSrc}
                autoPlay
                controls
            />
        </div>
    </div>
}

export default AudioPlayer;
