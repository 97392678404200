import React from "react"
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // 
import AudioPlayer from "./AudioPlayer";
import {
  useParams
} from "react-router-dom";


const styles = {
  display: "flex",
  width: "100vw",
  height: "100vh",
  backgroundImage: "url('./Ewness.png'),linear-gradient(rgba(0, 0, 0, 0.5),35%, rgba(255, 255, 255, 0))",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center"

}

function App() {
  const { code } = useParams()
  console.log(code)
  const location = useLocation();
  const Elem = ({ file }) => <div>
    {<div style={styles}></div>}
  </div>
  return <div>
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Elem file={code} />}/>
      <Route path="/:code" element={<AudioPlayer /> }/>

  
    </Routes>



  </div>
}

export default App;
